import {defineStore} from "pinia"
import {useCurrentPageRouteI18nStore} from "~/stores/current-page-route-i18n"
import {useUserStore} from "~/stores/user"
import type {MediaPageData} from '~/types/media/MediaPageData'

export const useMediaStore = defineStore('media', () => {

    const currentMediaId = ref<number|null>(null)
    const medias = ref<{[key: number]: MediaPageData}>({})
    const userStore = useUserStore()

    let _currentMediaData = ref<MediaPageData|null>(null)

    watch(() => userStore.userId, () => {
        // Clear store on sign in/out
        medias.value = {}
    })

    async function loadMediaData(id: number, auth: string | null, directoryCode: string | null): Promise<MediaPageData> {
        const params: any = {}

        if (auth) {
            params.auth = auth
        }

        if (directoryCode) {
            params.dir = directoryCode
        }

        const {data, error} = await useApiFetch<MediaPageData>(`/media/${id}`, {params, noCache: true})

        if (error.value || !data.value) {
            throw new Error('Could not load media data')
        }

        if (!auth) {
            medias.value[id] = data.value
        }

        return data.value
    }

    async function getMedia(id: number, andMakeCurrent = true, auth: string | null, directoryCode: string | null): Promise<MediaPageData> {

        const { storeRouteI18nData } = useCurrentPageRouteI18nStore()
        let loadedData: MediaPageData

        if (auth || Object.keys(medias.value).indexOf(id.toString()) === -1) {
            loadedData = await loadMediaData(id, auth, directoryCode)
        } else {
            loadedData = medias.value[id]
        }

        if (andMakeCurrent) {
            currentMediaId.value = id
            _currentMediaData.value = loadedData
            storeRouteI18nData(loadedData)
        }

        return loadedData
    }

    const currentMedia = computed(() => {
        return currentMediaId.value ? _currentMediaData.value : null
    })

    return {
        // currentMediaId,
        medias,
        getMedia,
        currentMedia,
    }
})